import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import './AdminDashboard.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AdminDashboard = () => {
  const { currentUser } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [roleRequests, setRoleRequests] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [activeTab, setActiveTab] = useState('users');
  const [activeSection, setActiveSection] = useState('list'); // 'list', 'details', 'message'
  const [searchQuery, setSearchQuery] = useState('');
  const [messageContent, setMessageContent] = useState('');
  const [messageError, setMessageError] = useState('');
  const [messageSending, setMessageSending] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showHtmlGuide, setShowHtmlGuide] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);

  // Use production URL when in production, localhost for development
  const API_URL = window.location.hostname === 'localhost' 
    ? 'http://localhost:3001/api'
    : 'https://firstveil.com/api';  // Replace with your actual production API URL

  useEffect(() => {
    fetchUsers();
    fetchRoleRequests();
    fetchNotifications();

    // Set up polling for new notifications
    const notificationInterval = setInterval(() => {
      fetchNotifications(true);
    }, 60000); // Check every minute

    return () => clearInterval(notificationInterval);
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    setError('');
    
    try {
      const response = await fetch(`${API_URL}/admin/users`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }
      
      const data = await response.json();
      setUsers(data);
    } catch (err) {
      console.error('Error fetching users:', err);
      setError('Failed to load users. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const fetchRoleRequests = async () => {
    setLoading(true);
    setError('');
    
    try {
      const response = await fetch(`${API_URL}/admin/role-requests`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch role requests');
      }
      
      const data = await response.json();
      setRoleRequests(data);
    } catch (err) {
      console.error('Error fetching role requests:', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchNotifications = async (silent = false) => {
    try {
      if (!silent) setLoading(true);
      
      const response = await fetch(`${API_URL}/admin/notifications`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('Error response:', errorData);
        return;
      }
      
      const data = await response.json();
      console.log('Notifications fetched successfully:', data);
      
      // Count unread notifications
      const unread = data.filter(notification => !notification.read).length;
      setUnreadNotifications(unread);
      
      setNotifications(data);
    } catch (err) {
      console.error('Error fetching notifications:', err);
    } finally {
      if (!silent) setLoading(false);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setActiveSection('list');
    setSelectedUser(null);
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setActiveSection('details');
    setMessageContent('');
    setMessageError('');
  };

  const handleBackToList = () => {
    setActiveSection('list');
  };

  const handleOpenMessageForm = () => {
    setActiveSection('message');
  };

  const handleRoleChange = async (userId, newRole) => {
    try {
      const response = await fetch(`${API_URL}/admin/users/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify({ role: newRole })
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to update user role');
      }
      
      // Update local state
      setUsers(users.map(user => 
        user.id === userId ? { ...user, role: newRole } : user
      ));
      
      setError('');
    } catch (err) {
      console.error('Error updating user role:', err);
      setError(`Error updating user role: ${err.message}`);
    }
  };

  const handlePasswordReset = async (userId) => {
    setConfirmDelete({
      type: 'resetPassword',
      userId,
      message: 'Are you sure you want to reset this user\'s password? This will generate a new random password and send it to their email.',
      confirmText: 'Reset Password',
      action: async () => {
        try {
          const response = await fetch(`${API_URL}/admin/users/${userId}/reset-password`, {
            method: 'POST',
            headers: {
              'x-auth-token': localStorage.getItem('auth_token')
            }
          });
          
          if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            throw new Error(errorData.message || 'Failed to reset password');
          }
          
          const data = await response.json();
          alert(`Password reset successful! The new password is: ${data.password}`);
          
          setError('');
        } catch (err) {
          console.error('Error resetting password:', err);
          setError(`Error resetting password: ${err.message}`);
        } finally {
          setConfirmDelete(null);
        }
      }
    });
  };

  const handleDeleteUser = async (userId) => {
    setConfirmDelete({
      type: 'deleteUser',
      userId,
      message: 'Are you sure you want to delete this user? This action cannot be undone.',
      confirmText: 'Delete User',
      action: async () => {
        try {
          const response = await fetch(`${API_URL}/admin/users/${userId}`, {
            method: 'DELETE',
            headers: {
              'x-auth-token': localStorage.getItem('auth_token')
            }
          });
          
          if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            throw new Error(errorData.message || 'Failed to delete user');
          }
          
          // Update local state
          setUsers(users.filter(user => user.id !== userId));
          
          if (selectedUser && selectedUser.id === userId) {
            setSelectedUser(null);
            setActiveSection('list');
          }
          
          setError('');
        } catch (err) {
          console.error('Error deleting user:', err);
          setError(`Error deleting user: ${err.message}`);
        } finally {
          setConfirmDelete(null);
        }
      }
    });
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    
    if (!selectedUser) {
      setMessageError('No user selected');
      return;
    }
    
    if (!messageContent || messageContent === '<p><br></p>') {
      setMessageError('Message cannot be empty');
      return;
    }
    
    setMessageSending(true);
    setMessageError('');
    
    try {
      // Log the request details for debugging
      console.log('Sending message to user ID:', selectedUser.id);
      console.log('Message content:', messageContent);
      
      // Try the endpoint with user ID in the path
      const endpoint = `${API_URL}/admin/users/${selectedUser.id}/message`;
      console.log('Using API endpoint:', endpoint);
      
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify({
          message: messageContent,
          fromAdmin: true
        })
      });
      
      // Log the response for debugging
      console.log('Response status:', response.status);
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('Error response data:', errorData);
        throw new Error(errorData.message || `Failed to send message: ${response.status}`);
      }
      
      // Clear the message form
      setMessageContent('');
      setActiveSection('details');
      alert(`Message sent to ${selectedUser.displayName}`);
    } catch (err) {
      console.error('Error sending message:', err);
      setMessageError(`Error sending message: ${err.message}`);
      
      // Show a more detailed error message to help with debugging
      alert(`Failed to send message. Error: ${err.message}\nPlease check the console for more details.`);
    } finally {
      setMessageSending(false);
    }
  };

  const handleApproveRequest = async (requestId, userId) => {
    setConfirmDelete({
      type: 'approveRequest',
      requestId,
      message: 'Are you sure you want to approve this role upgrade request?',
      confirmText: 'Approve Request',
      action: async () => {
        try {
          const response = await fetch(`${API_URL}/admin/role-requests/${requestId}/approve`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'x-auth-token': localStorage.getItem('auth_token')
            },
            body: JSON.stringify({ userId })
          });
          
          if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            throw new Error(errorData.message || 'Failed to approve request');
          }
          
          // Update local state
          setRoleRequests(roleRequests.map(request => 
            request.id === requestId ? { ...request, status: 'approved' } : request
          ));
          
          // Update user role in users list
          const [matchingRequest] = roleRequests.filter(request => request.id === requestId);
          if (matchingRequest) {
            setUsers(users.map(user => 
              user.id === matchingRequest.userId ? { ...user, role: matchingRequest.requestedRole } : user
            ));
          }
          
          setError('');
        } catch (err) {
          console.error('Error approving request:', err);
          setError(`Error approving request: ${err.message}`);
        } finally {
          setConfirmDelete(null);
        }
      }
    });
  };

  const handleDenyRequest = async (requestId) => {
    setConfirmDelete({
      type: 'denyRequest',
      requestId,
      message: 'Are you sure you want to deny this role upgrade request?',
      confirmText: 'Deny Request',
      action: async () => {
        try {
          const response = await fetch(`${API_URL}/admin/role-requests/${requestId}/deny`, {
            method: 'PUT',
            headers: {
              'x-auth-token': localStorage.getItem('auth_token')
            }
          });
          
          if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            throw new Error(errorData.message || 'Failed to deny request');
          }
          
          // Update local state
          setRoleRequests(roleRequests.map(request => 
            request.id === requestId ? { ...request, status: 'denied' } : request
          ));
          
          setError('');
        } catch (err) {
          console.error('Error denying request:', err);
          setError(`Error denying request: ${err.message}`);
        } finally {
          setConfirmDelete(null);
        }
      }
    });
  };

  const handleRetry = () => {
    setError('');
    setActiveTab('users');
    fetchUsers();
    fetchRoleRequests();
    fetchNotifications();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  // Filter users based on search term
  const filteredUsers = users.filter(user => {
    const searchLower = searchQuery.toLowerCase();
    return (
      user.email.toLowerCase().includes(searchLower) ||
      user.displayName.toLowerCase().includes(searchLower) ||
      user.role.toLowerCase().includes(searchLower)
    );
  });

  return (
    <div className="admin-dashboard">
      {error && (
        <div className="admin-error">
          <p>{error}</p>
          <button className="retry-button" onClick={handleRetry}>
            Retry
          </button>
        </div>
      )}
      
      <div className="mobile-tabs">
        <button 
          className={`mobile-tab ${activeTab === 'users' ? 'active' : ''}`} 
          onClick={() => handleTabChange('users')}
        >
          Users
        </button>
        <button 
          className={`mobile-tab ${activeTab === 'requests' ? 'active' : ''}`}
          onClick={() => handleTabChange('requests')}
        >
          Requests
          {roleRequests.filter(req => req.status === 'pending').length > 0 && (
            <span className="request-badge">
              {roleRequests.filter(req => req.status === 'pending').length}
            </span>
          )}
        </button>
        <button 
          className={`mobile-tab ${activeTab === 'notifications' ? 'active' : ''}`}
          onClick={() => handleTabChange('notifications')}
        >
          Notifications
          {unreadNotifications > 0 && (
            <span className="request-badge">
              {unreadNotifications}
            </span>
          )}
        </button>
      </div>
      
      <div className="tab-content">
        {activeTab === 'users' && (
          <div className="users-tab">
            {activeSection === 'list' && (
              <div className="users-list-section">
                <h2>Manage Users</h2>
                <div className="search-bar">
                  <input 
                    type="text" 
                    placeholder="Search users..." 
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
                
                <div className="users-list">
                  {filteredUsers.length > 0 ? (
                    filteredUsers.map(user => (
                      <div 
                        key={user.id} 
                        className="user-card"
                        onClick={() => handleUserSelect(user)}
                      >
                        <div className="user-card-header">
                          <div className="user-avatar">
                            {user.avatar ? (
                              <img src={user.avatar} alt={user.displayName} />
                            ) : (
                              <div className="avatar-placeholder">
                                {user.displayName.charAt(0).toUpperCase()}
                              </div>
                            )}
                          </div>
                          <div className="user-name-role">
                            <div className="user-name">{user.displayName}</div>
                            <div className={`user-role role-${user.role.toLowerCase()}`}>
                              {user.role}
                            </div>
                          </div>
                        </div>
                        <div className="user-email">{user.email}</div>
                      </div>
                    ))
                  ) : loading ? (
                    <div className="loading-container">
                      <div className="loading-spinner"></div>
                      <p>Loading users...</p>
                    </div>
                  ) : (
                    <div className="no-results">
                      <p>No users found</p>
                    </div>
                  )}
                </div>
              </div>
            )}
            
            {activeSection === 'details' && selectedUser && (
              <div className="user-details-section">
                <button className="back-button" onClick={handleBackToList}>
                  ← Back to Users
                </button>
                
                <div className="user-details-header">
                  <div className="user-avatar large">
                    {selectedUser.avatar ? (
                      <img src={selectedUser.avatar} alt={selectedUser.displayName} />
                    ) : (
                      <div className="avatar-placeholder">
                        {selectedUser.displayName.charAt(0).toUpperCase()}
                      </div>
                    )}
                  </div>
                  <div className="user-name-container">
                    <h2>{selectedUser.displayName}</h2>
                    <div className={`user-role role-${selectedUser.role.toLowerCase()}`}>
                      {selectedUser.role}
                    </div>
                  </div>
                </div>
                
                <div className="user-details-content">
                  <div className="detail-item">
                    <div className="detail-label">Email</div>
                    <div className="detail-value">{selectedUser.email}</div>
                  </div>
                  
                  <div className="detail-item">
                    <div className="detail-label">ID</div>
                    <div className="detail-value">{selectedUser.id}</div>
                  </div>
                  
                  <div className="detail-item">
                    <div className="detail-label">Joined</div>
                    <div className="detail-value">{formatDate(selectedUser.createdAt)}</div>
                  </div>
                  
                  <div className="detail-item">
                    <div className="detail-label">Role</div>
                    <div className="role-selector">
                      <select 
                        value={selectedUser.role}
                        onChange={(e) => handleRoleChange(selectedUser.id, e.target.value)}
                      >
                        <option value="commoner">Commoner</option>
                        <option value="adventurer">Adventurer</option>
                        <option value="admin">Admin</option>
                      </select>
                    </div>
                  </div>
                </div>
                
                <div className="user-actions">
                  <button 
                    className="action-button message-user"
                    onClick={handleOpenMessageForm}
                  >
                    Send Message
                  </button>
                  <button 
                    className="action-button reset-password"
                    onClick={() => handlePasswordReset(selectedUser.id)}
                  >
                    Reset Password
                  </button>
                  <button 
                    className="action-button delete-user"
                    onClick={() => handleDeleteUser(selectedUser.id)}
                  >
                    Delete User
                  </button>
                </div>
              </div>
            )}
            
            {activeSection === 'message' && selectedUser && (
              <div className="message-section">
                <button className="back-button" onClick={() => setActiveSection('details')}>
                  ← Back to User Details
                </button>
                
                <h2>Send Message to {selectedUser.displayName}</h2>
                
                {messageError && <div className="message-error">{messageError}</div>}
                
                <form onSubmit={handleSendMessage} className="message-form">
                  <div className="quill-editor-container">
                    <ReactQuill
                      theme="snow"
                      value={messageContent}
                      onChange={setMessageContent}
                      placeholder="Compose your message here..."
                      modules={{
                        toolbar: [
                          [{ 'header': [1, 2, 3, false] }],
                          ['bold', 'italic', 'underline', 'strike'],
                          [{'list': 'ordered'}, {'list': 'bullet'}],
                          ['blockquote', 'code-block'],
                          ['link', 'image'],
                          ['clean']
                        ],
                      }}
                    />
                  </div>
                  
                  <div className="message-actions">
                    <button 
                      type="submit" 
                      className="send-message-btn"
                      disabled={messageSending}
                    >
                      {messageSending ? 'Sending...' : 'Send Message'}
                    </button>
                    <button 
                      type="button" 
                      className="cancel-message-btn"
                      onClick={() => setActiveSection('details')}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'requests' && (
          <div className="requests-tab">
            <h2>Role Upgrade Requests</h2>
            
            <div className="requests-list">
              {roleRequests.length > 0 ? (
                roleRequests
                  .filter(request => request.status === 'pending')
                  .map(request => {
                    const user = users.find(u => u.id === request.userId);
                    return (
                      <div key={request.id} className="request-card">
                        <div className="request-card-header">
                          {user && (
                            <div className="requester-info">
                              <div className="user-avatar small">
                                {user.avatar ? (
                                  <img src={user.avatar} alt={user.displayName} />
                                ) : (
                                  <div className="avatar-placeholder">
                                    {user.displayName.charAt(0).toUpperCase()}
                                  </div>
                                )}
                              </div>
                              <div>
                                <div className="requester-name">{user.displayName}</div>
                                <div className="requester-email">{user.email}</div>
                              </div>
                            </div>
                          )}
                          <div className="request-date">{formatDate(request.createdAt)}</div>
                        </div>
                        
                        <div className="request-content">
                          <div className="request-type">
                            Requesting upgrade to: <span className={`role-${request.requestedRole.toLowerCase()}`}>{request.requestedRole}</span>
                          </div>
                          {request.reason && (
                            <div className="request-reason">
                              <h4>Reason:</h4>
                              <p>{request.reason}</p>
                            </div>
                          )}
                        </div>
                        
                        <div className="request-actions">
                          <button 
                            className="action-button approve"
                            onClick={() => handleApproveRequest(request.id, request.userId)}
                          >
                            Approve
                          </button>
                          <button 
                            className="action-button deny"
                            onClick={() => handleDenyRequest(request.id)}
                          >
                            Deny
                          </button>
                        </div>
                      </div>
                    );
                  })
              ) : loading ? (
                <div className="loading-container">
                  <div className="loading-spinner"></div>
                  <p>Loading requests...</p>
                </div>
              ) : (
                <div className="no-results">
                  <p>No pending requests</p>
                </div>
              )}
            </div>
          </div>
        )}
        
        {activeTab === 'notifications' && (
          <div className="notifications-tab">
            <h2>Notifications</h2>
            
            <div className="notifications-list">
              {notifications.length > 0 ? (
                notifications.map(notification => (
                  <div 
                    key={notification.id} 
                    className={`notification-card ${!notification.read ? 'unread' : ''}`}
                    onClick={() => handleDeleteUser(notification.id)}
                  >
                    <div className="notification-card-header">
                      <div className="notification-type">{notification.type}</div>
                      <div className="notification-date">{formatDate(notification.createdAt)}</div>
                    </div>
                    <div className="notification-content">
                      {notification.content}
                    </div>
                  </div>
                ))
              ) : loading ? (
                <div className="loading-container">
                  <div className="loading-spinner"></div>
                  <p>Loading notifications...</p>
                </div>
              ) : (
                <div className="no-results">
                  <p>No notifications</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      
      {confirmDelete && (
        <div className="confirm-modal">
          <div className="confirm-content">
            <h3>{confirmDelete.type === 'resetPassword' ? 'Reset Password' : 
                 confirmDelete.type === 'deleteUser' ? 'Delete User' :
                 confirmDelete.type === 'approveRequest' ? 'Approve Request' : 'Deny Request'}</h3>
            <p>{confirmDelete.message}</p>
            <div className="confirm-buttons">
              <button 
                className="confirm-button"
                onClick={confirmDelete.action}
              >
                {confirmDelete.confirmText}
              </button>
              <button 
                className="cancel-button"
                onClick={() => setConfirmDelete(null)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminDashboard; 